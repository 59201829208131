<template>
  <v-container fluid>
    <v-row justify="end">
      <v-col cols="auto">
        <div class="d-flex">
          <b class="sky--text">Masquer les catégories inactives</b>
          <v-checkbox v-model="activeSwitcher" class="mt-0 ml-2"></v-checkbox>
        </div>
      </v-col>
      <v-col cols="auto">
        <div class="d-flex">
          <b class="sky--text">Trier</b>
          <v-switch v-model="switcher" class="mt-0 ml-2"></v-switch>
        </div>
      </v-col>
    </v-row>
    <div v-if="!loader" class="category-list">
      <template v-for="(category, index) in roomsSubCategories">
        <component
          :is="categoryItem(category.services.length > 0)"
          :key="index"
          :category="category"
        ></component>
      </template>
      <CategoryListOfRoomAddItem @showCategoryDeepOne="deepOneModal = true" />
    </div>
    <div v-else class="text-center">
      <v-progress-circular
        :size="50"
        :width="2"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <CategoryListDeepOneModal
      :categories="categoryDeepOneList"
      :show="deepOneModal"
      @closeModal="
        deepOneModal = false;
        searchOfCategoryDeepTwo = '';
      "
      @selectedCatDeepOne="onSelectedCatDeepOne"
      @showCustomServiceModal="customServiceModal = true"
      @debouncedInput="(payload) => (searchOfCategoryDeepTwo = payload)"
    />

    <CategoryListDeepTwoModal
      v-if="selectedCatDeepOne"
      :key="deepTwoModal"
      :title="selectedCatDeepOne.name"
      :categories="categoryDeepTwoList"
      :roomQuotaCategories="customRoomQuotaCategories"
      :roomId="+$route.params.roomId"
      :show="deepTwoModal"
      @closeModal="deepTwoModal = false"
      @createRoomCategory="onCreateRoomCategory"
    />

    <AddCustomServiceModal
      :show="customServiceModal"
      :roomId="roomId"
      @closeModal="customServiceModal = false"
      @createCustomService="onCreateCustomService"
    />
  </v-container>
</template>

<script>
import { CategoryService } from "@/services/category.service.js";
export default {
  name: "CategoryListOfRoom",
  props: {
    roomId: {
      type: Number,
      required: true,
    },
    roomCategories: {
      type: Array,
      default: () => [],
    },
    roomCustomCategories: {
      type: Array,
      default: () => [],
    },
    tree: {
      type: Array,
      required: true,
    },
    roomServices: {
      type: Array,
      required: true,
    },
    roomProducts: {
      type: Array,
      required: true,
    },
    customServices: {
      type: Array,
      default: () => [],
    },
    quotaRooms: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AddCustomServiceModal: () =>
      import("~cp/Services/CustomServiceModal/AddCustomServiceModal"),
    CategoryListOfRoomItem: () =>
      import("./CategoryListOfRoomItem/CategoryListOfRoomItem"),
    CategoryListOfRoomItemActive: () =>
      import("./CategoryListOfRoomItem/CategoryListOfRoomItemActive"),
    CategoryListOfRoomAddItem: () =>
      import("./CategoryListOfRoomItem/CategoryListOfRoomAddItem.vue"),
    CategoryListDeepOneModal: () =>
      import("./CategoryListDeepOneModal/CategoryListDeepOneModal.vue"),
    CategoryListDeepTwoModal: () =>
      import("./CategoryListDeepTwoModal/CategoryListDeepTwoModal.vue"),
    CustomServiceItem: () =>
      import("./CategoryListOfRoomItem/CustomServiceItem.vue"),
  },
  data() {
    return {
      loader: false,
      deepOneModal: false,
      deepTwoModal: false,
      customServiceModal: false,
      selectedCatDeepOne: null,
      allCustomRoomQuotaCategories: [],
      searchOfCategoryDeepTwo: "",
      switcher: this.$route.query.sortByServices === "false" ? false : true,
      activeSwitcher: this.$route.query.hideInactive === "true" || false,
    };
  },
  computed: {
    categoryItem() {
      return (active) => {
        return active
          ? "CategoryListOfRoomItemActive"
          : "CategoryListOfRoomItem";
      };
    },
    customCategories() {
      return this.roomCustomCategories.map((cat) => {
        return {
          ...cat,
          image: "/images/designers-kitchens.jpg",
          services: this.roomServices.filter(
            (service) =>
              service.category_id === 1 &&
              service.quota_room_id === +this.$route.params.roomId
          ),
        };
      });
    },
    roomsSubCategories() {
      let sub = [];

      if (this.tree) {
        this.tree.forEach((cat) => {
          if (cat.children) {
            sub.push(...cat.children);
          }
        });
      }
      let arr = this.roomCategories
        .filter((roomCat) => !!sub.find((subItem) => roomCat === subItem.id))
        .map((roomCat) => {
          return {
            id: roomCat,
            name: sub.find((subItem) => roomCat === subItem.id)?.name,
            image: sub.find((subItem) => roomCat === subItem.id)?.image || null,
            services: this.roomServices
              .filter(
                (service) =>
                  service.category_id === roomCat &&
                  service.quota_room_id === +this.$route.params.roomId
              )
              .map((service) => {
                let roomProduct = this.roomProducts.find((product) => product.service_id === service.service_id)
                let roomProductPriceWithTax = roomProduct?.price_with_tax ? roomProduct.price_with_tax : 0
                let roomProductPriceDiscount = roomProduct?.discount_price ? roomProduct?.discount_price : roomProductPriceWithTax
                return {
                  ...service,
                  productPrice: roomProductPriceDiscount || 0,
                };
              }),
          };
        });
      let outputList = [...arr, ...this.customCategories];
      if (this.activeSwitcher) {
        outputList = outputList.filter((subcat) => subcat.services.length > 0);
      }
      return outputList.sort((roomCatA, roomCatB) => {
        if (this.switcher) {
          if (roomCatA.services.length < roomCatB.services.length) return 1;
          if (roomCatA.services.length > roomCatB.services.length) return -1;
          return 0;
        }
        return 0;
      });
    },
    categoryDeepOneList() {
      if (!this.tree) return [];
      const categories = this.tree.map((treeItem) => {
        let childrenCat =
          treeItem?.children.map((cat) => {
            return {
              ...cat,
              catNameWords: cat.name.toLowerCase().split(" "),
            };
          }) || [];
        let cat = {
          ...treeItem,
          children: childrenCat,
        };
        return cat;
      });
      if (this.searchOfCategoryDeepTwo === "") {
        return categories;
      }
      if (this.searchOfCategoryDeepTwo !== "") {
        return categories.filter((cat) => {
          return (
            cat.children
              .filter(
                (treeItem) =>
                  !this.roomsSubCategories.find((sub) => sub.id === treeItem.id)
              )
              .filter((child) => {
                const keys = this.searchOfCategoryDeepTwo
                  .trim()
                  .toLowerCase()
                  .split(" ");
                return child.catNameWords.some((el) => {
                  // Variant 1:
                  // return keys?.includes(el);

                  // Variant 2:
                  return (
                    keys.filter((key) => {
                      // console.log(`"todo" ${key}: ${el}`, el.startsWith(key));
                      return el?.startsWith(key);
                    })?.length > 0
                  );
                });
              })?.length > 0
          );
        });
      }
    },
    categoryDeepTwoList() {
      if (!this.selectedCatDeepOne || !this.selectedCatDeepOne.children) {
        return [];
      }

      return this.selectedCatDeepOne.children
        .filter(
          (treeItem) =>
            !this.roomsSubCategories.find((sub) => sub.id === treeItem.id)
        )
        .map((treeItem) => {
          let cat = { ...treeItem };
          return cat;
        });
    },
    customRoomQuotaCategories() {
      return this.allCustomRoomQuotaCategories.filter(
        (catRoom) => catRoom.quota_room_id === +this.$route.params.roomId
      );
    },
  },
  created() {
    Promise.all([this.getRoomCategories()])
      .then(([allCustomRoomQuotaCategories]) => {
        this.allCustomRoomQuotaCategories = allCustomRoomQuotaCategories.data;
      })
      .catch(() => {
        this.$store.commit(
          "snackbarModule/SET_ERROR_API",
          this.$t("snackbar.error.base")
        );
      });
  },
  watch: {
    switcher(newVal) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          sortByServices: newVal,
        },
      });
    },
    activeSwitcher(newVal) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          hideInactive: newVal,
        },
      });
    },
    "$route.params.roomId"() {
      this.loader = true;
      setTimeout(() => (this.loader = false), 350);
    },
  },
  methods: {
    onSelectedCatDeepOne(payload) {
      this.selectedCatDeepOne = payload;
      this.deepTwoModal = true;
    },
    async onCreateRoomCategory() {
      const categories = await this.getRoomCategories();
      this.allCustomRoomQuotaCategories = categories?.data || [];
      this.deepTwoModal = false;
      this.deepOneModal = false;
      this.$emit("createRoomCategory");
    },
    onCreateCustomService(customService) {
      this.deepOneModal = false;
      this.$emit("createCustomService", customService);
    },
    getRoomCategories() {
      return CategoryService.getQuotaRoomsCategories(
        this.quotaRooms.map((room) => room.id)
      );
    },
  },
};
</script>

<style src="./CategoryListOfRoom.scss" lang="scss" scoped></style>